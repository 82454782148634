<template>
  <div>
    <div> {{ booking.timetableBlockPrice.name }}</div>
    <div class="time-style">
      {{ startAt  }} - {{ endAt }}
    </div>
  </div>
</template>
<script>
import {toTimeFormat} from "@/utils/timezone";

export default {
  props: {
    booking: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    startAt () {
      return toTimeFormat(this.booking.startAt);
    },
    endAt () {
      return toTimeFormat(this.booking.endAt);
    }
  }
}
</script>
<style scoped lang="scss">
.time-style {
  color: #a1a1a1;
}
</style>
